import React from "react"

import Layout from "../Components/layout.js"
import SEO from "../Components/seo.js"

import Row from '../Components/Rows.jsx'
import Picture from '../Components/Picture.jsx'
import TextTiles from '../Components/TextTiles.jsx'

const About = () => (
  <Layout>
    <SEO title="About" />
    <Picture splash src='Splash9'/>
    <Row>
      <TextTiles
        tiles={[
          {
            title: 'Lucky and Happy',
            content: 'Over two generations of experiences in apparel industry, L&H Tag and Label carries high quality printed and woven labels. We have a variety of customers ranging from small independent manufacturers, large merchandisers, and wholesalers. We aim for customers satisfaction and we always thrive to find the better way to suit individuals needs.'
          }
        ]}
        cols={1}
      />
    </Row>
    <Row noPaddingTop>
      <TextTiles 
        tiles={[
          {
            title: 'Mission',
            content: 'L&H Tag and label was found under one motto: Provide customers with quality labels at an affordable price.'
          },
          {
            title: 'Partners',
            content: 'We proudly carry materials and machines from our sister company Printex Technology.'
          },
          {
            title: 'Wholesales/Retails',
            content: 'While we also carry special packages for wholesalers, we work with individual clients as well.'
          }
        ]}
      />
    </Row>
  </Layout>
)

export default About
